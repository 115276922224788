import React, { Component } from 'react';
import { InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import css from 'react-datepicker/dist/react-datepicker.css';
import { format, setDate } from 'date-fns';
const DatePickerStyle = styled.div`
${css}
.react-datepicker-popper{
    z-index: 3;
}
.react-datepicker {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 0;
    border-color: #ececec;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.30);
}
.react-datepicker__header {
    padding-top: 12px;
    background-color: #F9F9F9;
    border-radius: 0;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle{
    border-bottom-color: #F9F9F9;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before{
    border-bottom-color: #ECECEC;
}
.react-datepicker__month {
    margin: 7px 14px;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 28px;
  line-height: 28px;
  margin: 2px;
}
.react-datepicker__current-month {
    font-size: 14px;
    font-weight: 500;
}
.react-datepicker__navigation {
  top: 14px;
  line-height: 21px;
  border: 7px solid transparent;
}
.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 14px;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 14px;
  left: 220px;
}
.react-datepicker__day--selected, .react-datepicker__day--selected:hover, .react-datepicker__day:hover{
    border-radius: 0;
}
`;

export interface DatePickerProps {
  selectedDate?: Date;
  onChange: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  filterDate?: (date: Date) => boolean;
  disabled?: boolean;
}

export class DatePicker extends Component<DatePickerProps> {
  public static defaultProps: Partial<DatePickerProps> = {
    minDate: undefined,
    maxDate: undefined,
    disabled: false,
  };

  renderDateInput = (): JSX.Element => {
    return (
      <InputGroup>
        <input
          className="form-control"
          value={this.props.selectedDate ? format(new Date(this.props.selectedDate), 'MM/dd/yyyy') : undefined}
          readOnly
        />
        <InputGroup.Addon>
          <span className='glyphicon glyphicon-calendar' />
        </InputGroup.Addon>
      </InputGroup>
    );
  };

  dateOnChange = (date: Date) => {
    this.props.onChange(date);
  };

  filterDate = (date: Date) => {
    if (this.props.filterDate) {
      return this.props.filterDate(date);
    }
    return true;
  }

  render() {
    return (
      <DatePickerStyle>
        <ReactDatePicker
          className='date-input'
          selected={this.props.selectedDate}
          customInput={this.renderDateInput()}
          onChange={this.dateOnChange}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          filterDate={this.filterDate}
          adjustDateOnChange
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
              boundariesElement: 'viewport',
              priority: ['left', 'right'],
              padding: 10
            }
          }}
          disabled={this.props.disabled}
        />
      </DatePickerStyle>
    );
  }
}
