import { BaseEvent, EventData } from '../models';
import { hasProperties } from '../utils';

interface PageInfoEvent extends BaseEvent {
  event: 'pageInfo';
  page?: PageInfoEventData;
  site?: SiteInfoEventData;
}

type PageInfoEventData = EventData &
  Partial<{
    canonical: string;
    name: string;
    section: string[];
  }>;

type SiteInfoEventData = EventData &
  Partial<{
    language: string;
    region: string;
    name: string;
  }>;

export const pageInfoEvent = (
  page?: PageInfoEventData,
  site?: SiteInfoEventData
): PageInfoEvent => ({
  event: 'pageInfo',
  ...(hasProperties(page) && { page }),
  ...(hasProperties(site) && { site })
});
