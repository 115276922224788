import { useContext, useEffect, useState } from "react";
import { ChrLdContext } from "./ChrLdContext";

export const useChrLdFlags = () => {
  const [flags, setFlags] = useState({} as { [key: string]: boolean });
  const { ldClient } = useContext(ChrLdContext);

  useEffect(() => {
    let mounted = true;
    //https://docs.launchdarkly.com/sdk/client-side/javascript#subscribing-to-feature-flag-changes
    const handleFlagsChange = (values: { [key: string]: boolean }) => {
      setFlags((prevFlags) =>
        Object.keys(values).reduce((prev, key) => {
          return { ...prev, [key]: !!values[key] };
        }, prevFlags)
      );
    };

    ldClient.on("change", handleFlagsChange);

    const initialize = async () => {
      await ldClient.waitForInitialization();

      if (!mounted) {
        return;
      }

      const allFlags = ldClient.allFlags();
      setFlags(allFlags);
    };

    initialize();

    return () => {
      mounted = false;
      ldClient.off("change", handleFlagsChange);
    };
  }, [ldClient, setFlags]);

  return flags;
};
