import { BaseEvent, EventData } from '../models';

interface PageInitializedEvent extends BaseEvent {
  event: 'pageInitialized';
}

export const pageInitializedEvent = (
  data?: EventData
): PageInitializedEvent => ({
  event: 'pageInitialized',
  ...data
});
