import OktaAuth from '@okta/okta-auth-js';
import { AuthError } from './AuthError';
import { getStateSession,
  setStateSession,
  removeStateSession,
  parseState,
  getRedirectUri,
  setRetry,
  getRetry,
  removeRetry } from './utils';
import decode from 'jwt-decode';


const accessTokenKey = "accessToken";
const idTokenKey = "idToken";

export interface OAuthConfig {
  clientId: string,
  domain: string,
  responseType: string,
  redirectUri: string,
  audience: string,
  scopes: string[]
}

export interface QueryParams {
  post_logout_redirect_uri: string
}

export interface RenewTokenResult {
  accessToken: string,
  identityToken?: string,
  identity?: any,
}
export interface ChrDecodedJwt {
  sub:string,
  name:string,
  email:string,
  ver:number,
  iss:string,
  aud:string,
  iat:number,
  exp: number,
  jti:string,
  amr:Array<string>,
  idp:string,
  nonce:string
  preferred_username: string,
  auth_time: number,
  at_hash: string
  samAccountName: string,
}

export interface ChrAuthClient<T = ChrDecodedJwt> {
  logout: (queryParams: QueryParams) => Promise<void>,
  login: () => Promise<void>,
  getIdentity: () => Promise<T>,
  getAccessToken: () => Promise<string>,
  getIdentityToken: () => Promise<string>,
  ensureAuthed: () => Promise<RenewTokenResult>,
  onRenewed: (callback: (accessToken: string, identityToken: string)=> void) => void
}

export interface CommonAuthConfig {
  oAuthAudience: string
  oAuthClientId: string
  oAuthDomain: string
}

export interface Token {
  scopes: string[];
  expiresAt: number;
  accessToken?: string;
  idToken?: string;
}

export default function createClient<T>(commonConfig: CommonAuthConfig, overrides?: Partial<OAuthConfig>,
  accessToken?: Token, idToken? : Token): ChrAuthClient<T> {
  const badConfigOptions = [];

  if (!commonConfig) {
    throw new AuthError('missing_configuration', 'Missing required oAuth client configuration');
  }

  const config: OAuthConfig = {
    ...{
      redirectUri: getRedirectUri(),
      connection: 'chradfs',
      scopes: ['openid', 'profile', 'email'],
      responseType: 'id_token token',
      audience: commonConfig.oAuthAudience,
      clientId: commonConfig.oAuthClientId,
      domain: commonConfig.oAuthDomain
    },
    ...overrides
  }

  // do validation of config blob
  if (!config.clientId) {
    badConfigOptions.push('No oAuth client id specified in configuration');
  }

  if (!config.domain) {
    badConfigOptions.push('No oAuth domain specified in configuration');
  }

  if (!config.responseType) {
    badConfigOptions.push('No oAuth response type specified in configuration');
  }

  if (!config.redirectUri) {
    badConfigOptions.push('No oAuth redirect uri specified in configuration');
  }

  if (!config.audience) {
    badConfigOptions.push('No oAuth audience specified in configuration');
  }

  if (!config.scopes) {
    badConfigOptions.push('No oAuth scopes specified in configuration');
  }

  if (badConfigOptions.length > 0) {
    throw new AuthError('missing_configuration', badConfigOptions.join(', '))
  }

  const authRetryKey = `${commonConfig.oAuthClientId}-auth-retry`;
  setRetry(authRetryKey, 0);

  //new up the thing we will use internally
  const oktaAuthClient = new OktaAuth({
    scopes: config.scopes,
    issuer: config.domain,
    clientId: config.clientId,
    redirectUri: config.redirectUri,
    tokenManager: {
      storage: "memory",
      expireEarlySeconds: 120,
      autoRenew: true
    }
  });

  if(accessToken) {
    try {
      oktaAuthClient.tokenManager.add(accessTokenKey, accessToken)
    } catch (err) {
      throw new AuthError(err.errorCode, err.message);
    }
  }

  if(idToken) {
    try {
      oktaAuthClient.tokenManager.add(idTokenKey, idToken)
    } catch (err) {
      throw new AuthError(err.errorCode, err.message);
    }
  }

  // declare anything that needs to persist w/in scope (any stateful stuff)
  oktaAuthClient.tokenManager.on('expired', async function (key: string) {
    try {
      await oktaAuthClient.tokenManager.renew(key);
    }
    catch (err) {
      //If Session is bad
      client.login();
      throw new AuthError(err.errorCode, err.message);
    }
  });

  const client = {
    logout: async (queryParams: QueryParams) => {
      if (!queryParams.post_logout_redirect_uri) {
        throw new Error('Please specify queryParams.post_logout_redirect_uri so we can send the user somewhere after successfully logging out');
      }
      await oktaAuthClient.signOut();
      window.location.assign(queryParams.post_logout_redirect_uri);
    },
    login: async () => {
      const url = location.href.replace(new RegExp(document.baseURI, 'gi'), '') || document.baseURI;
      const state = setStateSession(url);

      await oktaAuthClient.token.getWithRedirect({
        scopes: config.scopes,
        responseType: ['token', 'id_token'],
        state: state
      });
    },
    getIdentity: async (): Promise<T> => {
      try {
        let token = await oktaAuthClient.tokenManager.get(idTokenKey);
        return decode(token.idToken);
      }
      catch { return null; }
    },
    getAccessToken: async (): Promise<string> => {
      try {
        let token = await oktaAuthClient.tokenManager.get(accessTokenKey);
        return token.accessToken
      }
      catch (err) {
        throw new AuthError(err.errorCode, err.message);
      }
    },
    getIdentityToken: async (): Promise<string> => {
      try {
        let token = await oktaAuthClient.tokenManager.get(idTokenKey);
        return token.idToken
      }
      catch (err) {
        throw new AuthError(err.errorCode, err.message);
      }
    },
    ensureAuthed: async (): Promise<RenewTokenResult> => {
      const state = parseState(window.location.href);

      //we are not being redirected after logging in
      if (window.location.href.indexOf("access_token") == -1) {
          setRetry(authRetryKey, Number(getRetry(authRetryKey)) + 1)
          try {
            // token returns if not expired
            let accessTokenObject = await oktaAuthClient.tokenManager.get(accessTokenKey);
            let idTokenObject = await oktaAuthClient.tokenManager.get(idTokenKey);
            if (accessTokenObject && idTokenObject) {
              return {
                accessToken: accessTokenObject.accessToken,
                identityToken: idTokenObject.idToken,
                identity: idTokenObject.claims
              };
            }
            //We're missing a token! Check the session for something, anything.
            else {
              const exists = await oktaAuthClient.session.exists();
              if(exists) {
                const session = await oktaAuthClient.session.get()
                // Session Exists (thanks goodness)
                if (session.status != "ACTIVE") {
                    //but session is not active, throw the error and so we can re-auth
                    throw new AuthError('inactive_session', `session was ${session.status}`);
                }
                // Session Exists and is active (thanks goodness) , refresh tokens using session
                return await refreshTokensWithSession(oktaAuthClient, config, state, session);
              }
              throw new AuthError('inactive_session', `session does not exist.`);
            }
          }
          catch (err) {
            if(getRetry(authRetryKey) === '3') {
              throw new AuthError(err.errorCode, err.message);
            }
            client.login();
            throw new AuthError(err.errorCode, err.message);
          }
      }
      // we are being redirected with a token
      else {
        setRetry(authRetryKey, Number(getRetry(authRetryKey)) + 1)
        try {
          return await parseFromUrl(oktaAuthClient, state, authRetryKey);
        } catch (err) {
          if(getRetry(authRetryKey) === '3') {
            throw new AuthError(err.errorCode, err.message);
          }
          // If there are more error messages to check for, we should put the list
          // in a separate function.

          // If the error message is that the JWT was issued in the future, this means that the users
          // clock is off by more than five minutes. We don't want to login in this case, so the UI
          // can respond with a message to the user.
          // If it's any other error message, then let it login.
          if (err.message.indexOf("The JWT was issued in the future") === -1) {
            client.login();
            throw new AuthError(err.errorCode, err.message);
          }
        }
      }
    },
    onRenewed: function(callback: (accessToken: string, idToken: string) => void) {
      oktaAuthClient.tokenManager.on('renewed', async function(key: string, freshToken: Token, oldToken: Token){
        const accessToken = (key === accessTokenKey) ? freshToken.accessToken : await client.getAccessToken();
        const identityToken = (key === idTokenKey) ? freshToken.idToken : await client.getIdentityToken();

        callback(accessToken, identityToken);
      });
    }
  }

  return client;
}

async function parseFromUrl(oktaAuthClient: any, state: string, authRetryKey: string) {
  const tokens = await oktaAuthClient.token.parseFromUrl();
  oktaAuthClient.tokenManager.add(accessTokenKey, tokens[0]);
  oktaAuthClient.tokenManager.add(idTokenKey, tokens[1]);
  if (state) {
    const decodedState = decodeURIComponent(state);
    const sessionState = getStateSession(decodedState);
    removeStateSession(decodedState);
    if (sessionState) {
      window.history.replaceState(null, null, sessionState);
    }
  }

  removeRetry(authRetryKey);
  return {
    accessToken: tokens[0].accessToken,
    identityToken: tokens[1].idToken,
    identity: tokens[1].claims
  };
}

async function refreshTokensWithSession(oktaAuthClient: any, config: OAuthConfig, state: string, session: any) {
  const tokens = await oktaAuthClient.token.getWithoutPrompt({
    scopes: config.scopes,
    responseType: ['token', 'id_token'],
    state: state,
    sessionToken: session.id,
  });
  oktaAuthClient.tokenManager.add(accessTokenKey, tokens[0]);
  oktaAuthClient.tokenManager.add(idTokenKey, tokens[1]);
  return {
    accessToken: tokens[0].accessToken,
    identityToken: tokens[1].idToken,
    identity: tokens[1].claims
  };
}
