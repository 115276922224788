import Autocomplete from 'react-autocomplete';
import React, { Component, createRef } from 'react';
import styled from 'styled-components';

export interface AutocompleteWrapperProps {
  cities: any[];
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onSelect: (value: string, city: any) => void;
  onFocusChanged: (value: boolean) => void;
  onClearClick: () => void;
}

const StyledMenu = styled.div`
  border-radius: 3px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  padding: 2px 0;
  font-size: 90%;
  position: fixed;
  overflow: auto;
  max-height: 50%;
  top: unset;
  left: unset;
`;

class AutocompleteWrapper extends Component<AutocompleteWrapperProps> {
  autoCompleteRef = createRef<Autocomplete>();

  recalculateHighlightedIndex = (cityId: number) => {
    const items = this.autoCompleteRef.current.getFilteredItems(this.autoCompleteRef.current.props)
    const currentIndex = items.findIndex((item) => {
      return item.id === cityId
    })
    this.autoCompleteRef.current.setState({
      highlightedIndex: currentIndex !== -1 ? currentIndex : 0
    })
  }

  componentDidMount() {
    const instance = this.autoCompleteRef.current;

    const originalMaybeAutoCompleteText = instance.maybeAutoCompleteText;

    instance.maybeAutoCompleteText = (state, props) => {
      const whatTheyThinkItShouldBe = originalMaybeAutoCompleteText.call(
        instance,
        state,
        props
      );
      if (
        state.isOpen &&
        whatTheyThinkItShouldBe.highlightedIndex === null &&
        instance.getFilteredItems(props).length
      ) {
        whatTheyThinkItShouldBe.highlightedIndex = 0;
      }
      return whatTheyThinkItShouldBe;
    };
  }

  focus = () => {
    this.props.onFocusChanged(true);
  };

  unfocus = () => {
    this.props.onFocusChanged(false);
  };

  render() {
    return (
      <Autocomplete
        inputProps={{
          onBlur: this.unfocus,
          onFocus: this.focus,
          placeholder: this.props.placeholder
        }}

        ref={this.autoCompleteRef}
        items={this.props.cities}
        getItemValue={item => item.label}
        renderItem={(item, highlighted) => (
          <div
            key={item.id}
            style={{ backgroundColor: highlighted ? '#eef' : 'transparent' }}
          >
            {item.label}
          </div>
        )}
        renderInput={inputProps => (
          <div className="wcl-auto-complete">
            <span
              className="ns-icon ns-close"
              onClick={this.props.onClearClick}
            />
            <input {...inputProps} className="form-control" />
          </div>
        )}
        renderMenu={items => (
          <StyledMenu className="wcl-auto-complete-menu" children={items} />
        )}
        value={this.props.value}
        onChange={this.props.onChange}
        onSelect={(value, item) => {
          this.recalculateHighlightedIndex(item.id)
          this.props.onSelect(value, item)
        }}
        selectOnBlur={true}
      />
    );
  }
}

export default AutocompleteWrapper;
