import { useContext, useEffect, useState } from "react";
import { ChrLdContext } from "./ChrLdContext";

export const useChrLdFlag = (flagKey: string) => {
  const [flag, setFlag] = useState(false);
  const { ldClient } = useContext(ChrLdContext);

  useEffect(() => {
    let mounted = true;
    //https://docs.launchdarkly.com/sdk/client-side/javascript#subscribing-to-feature-flag-changes
    const handleFlagChange = (value: any) => setFlag(!!value);

    ldClient.on(`change:${flagKey}`, handleFlagChange);

    const initialize = async () => {
      await ldClient.waitForInitialization();
      if (!mounted) {
        return;
      }
      var flagValue = ldClient.variation(flagKey, false);
      setFlag(!!flagValue);
    };

    initialize();

    return () => {
      mounted = false;
      ldClient.off(`change:${flagKey}`, handleFlagChange);
    };
  }, [setFlag, flagKey, ldClient]);

  return flag;
};
