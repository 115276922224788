import { BaseEvent, EventData } from '../models';

interface UserDetectedEvent extends BaseEvent {
  event: 'userDetected';
  user: UserDetectedEventData;
}

type UserDetectedEventData = EventData &
  Partial<{
    id: string;
    type: string;
    customer: Partial<{
      code: string;
      name: string;
    }>;
    carrier: Partial<{ program: string; code: string; name: string }>;
    impersonator: Partial<{
      id: string;
      source: string;
    }>;
  }>;

export const userDetectedEvent = (
  user: UserDetectedEventData
): UserDetectedEvent => ({
  event: 'userDetected',
  user
});
