import { ChrLdContext } from './ChrLdContext';
import { initialize } from 'launchdarkly-js-client-sdk';
import React from 'react';

export type UserBase = {
    [k: string]: string | true
}
export type NamedUser = UserBase & { key: string, anonymous?: never }
export type AnonymousUser = UserBase & { anonymous: true, key?: never }
export type User = NamedUser | AnonymousUser

export interface Options {
    /**
     * Sets preconfigured flag variations prior to client initialization.
     */
    [property: string]: any
}

export interface ChrLdProviderProps {
    /**
     * Used to identify environment set of feature flags. Should be Tokenized.
     */
    envKey: string,
    /**
     * The User Object, idenifies a user requesting a flag variation.
     */
    user: User,
    /**
     * Custom options to allow ld client to perform performance tasks
     */
    options?: Options,
    children?: any
}

export default (props: ChrLdProviderProps): React.ReactElement => {
    const user = {
        key: props.user.key,
        anonymous: props.user.anonymous
    };
    
    let options = props.options;

    if(!props.envKey) {
        throw Error("Missing Prop: Environment Key");
    }

    if (user.anonymous) {
        user.key = "46bcf15e-bf7c-4b38-9f6a-dbc2a201dfdf";
    }

    if(!user.key) {
        throw Error("Missing Prop: User Key");
    }

    if(!options) {
        options = {}
    }

    options["allAttributesPrivate"] = true;

    const client = initialize(props.envKey, user, options);

    return (
        <ChrLdContext.Provider value={{ldClient: client}}>
            {props.children}
        </ChrLdContext.Provider>
    )

}
