export interface CommonConfig {
  // these values do not need to change between environments
  enterpriseLoggingAppId: string
  oAuthAudience: string

  // these values are tokenized, swapped per environment
  loggingApiUri: string
  analyticsId: string
  citySearchApiUri: string
  productUrl: string
  oAuthClientId: string
  oAuthDomain: string

  // other values - used to communicate between the application and new navisphere shell
  applicationName: string
  navisphereSearchApiUrl: string
}

let configPromise: Promise<CommonConfig>;
const configRequest = () => configPromise || (configPromise = (() => {
  let baseUri: string | undefined = document.baseURI;
  if (!baseUri) {
    // use the base element's href attribute, but fall back to undefined if it's not there, or the attribute is not set
    baseUri = document.querySelector('base')?.getAttribute('href') ?? undefined;
  }
  let result = baseUri;
  if(!result || result.startsWith('./')){
    result = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  }

  /**
   * ie's document.baseURI is undefined, so if the base element's value
   * is a pathname (like for online sites), it will be missing the
   * protocol and host - stuff it in if it is not specified so we
   * end up with a valid base uri
   */
  if (!result.match(/^http/i)) {
    result = result.startsWith('/') ? result : `/${result}`;
    result = `${window.location.protocol}//${window.location.host}${result}`;
  }

  /**
   * When hash routing is used document.baseURI will include the hash path.
   * We don't want the hash path, only the base route so slice off everything
   * after the hash
   */
  result = result.split('#')[0];

  // we cannot use `new URL(result).pathname` as IE does not have URL, and the polyfill is gigantic
  const pathname = result.slice(result.indexOf(window.location.host) + window.location.host.length);

  let configJsonPath = `/${pathname}/app-config.json?${Date.now()}`;
  while (/\/\//.test(configJsonPath)) {
    configJsonPath = configJsonPath.replace('//', '/');
  }

  return fetch(configJsonPath).then(async response => {
    try {
      const json = await response.json()
      return json;
    } catch(e: any) {
      throw new Error("Invalid json in app-config.json");
    }
  })
})());

export const getConfiguration = <T extends CommonConfig>(): Promise<T> => {
  return configRequest() as Promise<T>;
}
