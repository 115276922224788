import React, { FC, ReactNode, useEffect } from 'react';
import { addScriptToHead, track } from '../utils';
import { AnalyticsContext } from './analytics-context';

interface AnalyticsProps {
  children: ReactNode;
  scriptSrc: string;
}

export const AnalyticsProvider: FC<AnalyticsProps> = ({
  scriptSrc,
  children
}) => {
  useEffect(() => {
    addScriptToHead(scriptSrc);
  }, [scriptSrc]);

  return (
    <AnalyticsContext.Provider value={track}>
      {children}
    </AnalyticsContext.Provider>
  );
};
